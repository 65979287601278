.back {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: flex-start;

  height: 30px;

  justify-self: start;

  cursor: pointer;

  margin-top: 56px;
}

.back > .icon {
  background-image: url("resources/rotate-icon.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  background-origin: content-box;
  width: 42px;
  height: 42px;

  margin-right: 20px;

  transition: all 0.4s ease-out;
}

.back:hover > .icon {
  transform: rotate(-360deg);
}

.back > .text {
  user-select: none;
  color: #888;
  font-family: "MinionPro", sans-serif;
  font-size: 42px;

  border-bottom: 2px solid #888;
}

@media (max-width: 1000px) {
  .back > .text {
    font-size: 20px;
  }
}
