.headline {
  font-family: "MinionPro", sans-serif;
  font-size: 72px;
}

.headline > .name {
  color: #bd0000;
  line-height: 81px;
}

.headline > .text {
  color: #000;
}


@media (max-width: 1500px) {
  .headline {
    font-size: 5vw;
  }

  .headline > .name {
    line-height: 6vw;
  }
}


@media (max-width: 1000px) {
  .headline {
    font-size: 40px;
  }

  .headline > .name {
    line-height: 50px;
  }
}
