.textarea {
  margin-top: 70px;
  margin-bottom: 80px;
  border-bottom: 1px solid black;
}


.textarea > .input {
  display: block;
  width: 100%;

  outline: none;
  border: none;
  resize: none;
  overflow: hidden;
  padding: 0;

  font-family: MinionPro, sans-serif;

  font-size: 24px;
  line-height: 27px;
}


@media (max-width: 1000px) {
  .textarea {
    margin-top: 40px;
    margin-bottom: 50px;
  }
  .textarea > .input {
    font-size: 18px;
  }
}
