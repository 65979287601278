.block {
  display: block;
  transition: background-color 300ms ease-out;
  background-color: #232323;
  height: 70px;
  color: white;

  font-family: MinionPro, sans-serif;
  text-decoration: none;

  font-size: 24px;
  line-height: 70px;

  padding-left: 15vw;
}

@media (max-width: 1000px) {
  .block {
    padding-left: 20px;
    height: 40px;
    line-height: 40px;
    font-size: 18px;
  }
}

.link {
  text-decoration: underline;
  transition: color 300ms ease-out;
  color: white;
}

.block:hover .link {
  color: #bd0000;
}
