.button {
  border: none;
  outline: none;
  background-color: #bd0000;
  color: #fefefe;
  font-family: "MinionPro", sans-serif;
  border-radius: 4px;
  height: 85px;
  font-size: 60px;

  cursor: pointer;

  padding: 0 32px;
  display: inline-block;
  justify-self: start;
}

@media (max-width: 1500px) {
  .button {
    height: 5.2vw;
    font-size: 4vw;
  }
}

@media (max-width: 1000px) {

  .button {
    height: 40px;
    font-size: 24px;
  }
}
