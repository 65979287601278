html, body, #root {
  height: 100%;
}

body {
  margin: 0;
}

*, *::before, *::after {
  position: relative;
  box-sizing: border-box;
}


@font-face {
  font-family: 'MinionPro';
  src: url('/src/fonts/MinionProRegular.otf');
  src: url('/src/fonts/MinionProRegular.otf') format('opentype');
  font-style: normal;
  font-weight: normal;
}
